<template>
  <div>
    <div class="header">
      <img src="@/assets/mobile/logo.png" class="logo" @click="goback" />
      <img
        src="@/assets/mobile/menu.png"
        class="menu"
        @click="openMenu"
        v-if="!isShow"
      />
      <img
        src="@/assets/mobile/close.png"
        class="close"
        @click="closeMenu"
        v-else
      />
    </div>
    <router-view />
    <div class="bottom">
      <div class="bottom_navigation">
        <div v-for="(item, index) in bottom" :key="index">
          <div class="bottom_navigation_item" @click="showSecondary(index)">
            <div>{{ item.name }}</div>
            <img
              src="@/assets/mobile/greyright.png"
              class="bottom_navigation_icon"
              v-if="!item.isShow"
            />
            <img
              src="@/assets/mobile/top.png"
              class="bottom_navigation_icon2"
              v-else
            />
          </div>
          <div :class="[item.isShow ? 'showdropdown' : 'nonedropdown2']">
            <div
              class="bottom_navigation_secondary"
              v-for="(citem, cindex) in item.chirden"
              :key="cindex"
              @click="productMore(citem)"
            >
              {{ citem.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_intro">
        <div class="bottom_company">锶想（广州）科技有限公司</div>
        <div class="bottom_contact">公司电话：020-8092 2872</div>
        <div class="bottom_contact">销售电话：17600058551（陈先生）</div>
        <div class="bottom_contact">官方邮箱：sales@huahuico.com</div>
        <div class="bottom_contact">
          公司地址：广州市黄埔区开创大道2819号6楼612房
        </div>
        <div class="bottom_business">
          <div class="bottom_copyright">
            <img src="@/assets/mobile/id.png" class="bottom_copyright_icon" />
            <div>2023 锶想（广州）科技有限公司Srthinker(GuangZhou)</div>
          </div>
          <div class="bottom_business_id">Technology Co.,Ltd.</div>
          <div class="bottom_business_id">备案号： 粤ICP备19071903号</div>
        </div>
      </div>
    </div>

    <!-- 菜单列表 -->
    <div class="dowm_menu" v-if="isShow">
      <div class="menu_left">
        <div
          v-for="item in menu"
          :key="item.title"
          :class="[
            'menu_item',
            selectedMenuindex == item.index ? 'menu_item_selected' : '',
          ]"
          @click="selectMenu(item)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="menu_right">
        <div
          class="menu_product"
          v-for="item in productList"
          :key="item.name"
          @click="jump(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedMenuindex: 1,
      activeNames: [],
      isShow: false,
      menu: [
        {
          title: "产品中心",
          index: 1,
          chirden: [
            {
              name: "证码通RC13",
              url: "moblierc13",
              type: 1,
            },
            {
              name: "证码通RC20",
              url: "MoblieRc20",
              type: 1,
            },
            {
              name: "证码通RC10",
              url: "MoblieRc10",
              type: 1,
            },
            {
              name: "智能电子学生证",
              url: "MoblieStudent",
              type: 1,
            },
            {
              name: "智能发卡收银机AT21",
              url: "moblieat21",
              type: 1,
            },
            {
              name: "双屏人证核验终端AT31",
              url: "MoblieAt31",
              type: 1,
            },
            {
              name: "双屏收银机ST-B2",
              url: "MoblieStb2",
              type: 1,
            },
            {
              name: "实名制平板RC14",
              url: "MoblieRc14",
              type: 1,
            },
            {
              name: "查看更多",
              url: "mobileProduct",
              type: 1,
            },
          ],
        },
        {
          title: "解决方案",
          index: 2,
          chirden: [
            {
              name: "智慧园区",
              type: 1,
              url: "park",
            },
            {
              name: "智慧校园",
              type: 1,
              url: "campus",
            },
            {
              name: "智慧交通",
              type: 1,
              url: "traffic",
            },
            {
              name: "智慧工地",
              type: 1,
              url: "construstion",
            },
            {
              name: "智慧厅店",
              type: 1,
              url: "construstion",
            },
            {
              name: "智慧新零售",
              type: 1,
              url: "construstion",
            },
            {
              name: "智慧公租房",
              type: 1,
              url: "public",
            },
          ],
        },
        {
          title: "关于锶想",
          index: 3,
          chirden: null,
          url: "about",
        },
        {
          title: "商务合作",
          index: 4,
          chirden: null,
          url: "cooperate",
        },
        {
          title: "开放平台",
          index: 5,
          chirden: [
            {
              name: "人员管理平台",
              url: "http://web.verification.srthinker.com/",
              type: 2,
            },
            {
              name: "证码核验平台",
              url: "http://web.antiepidemic.srthinker.com/",
              type: 2,
            },
          ],
        },
      ],
      bottom: [
        {
          name: "产品中心",
          isShow: false,
          chirden: [
            {
              name: "智能通行",
              url: "mobileProduct",
              index: 1,
              type: 1,
            },
            {
              name: "自助服务",
              url: "mobileProduct",
              index: 2,
              type: 1,
            },
            {
              name: "实名核验",
              url: "mobileProduct",
              index: 3,
              type: 1,
            },
            {
              name: "数据分析",
              url: "mobileProduct",
              index: 4,
              type: 1,
            },
          ],
        },
        {
          name: "解决方案",
          isShow: false,
          chirden: [
            {
              name: "智慧园区",
              type: 2,
              url: "park",
            },
            {
              name: "智慧校园",
              type: 2,
              url: "campus",
            },
            {
              name: "智慧交通",
              type: 2,
              url: "traffic",
            },
            {
              name: "智慧工地",
              type: 2,
              url: "construstion",
            },
            {
              name: "智慧厅店",
              type: 2,
              url: "construstion",
            },
            {
              name: "智慧新零售",
              type: 2,
              url: "construstion",
            },
            {
              name: "智慧公租房",
              type: 2,
              url: "public",
            },
          ],
        },
        {
          name: "开发平台",
          isShow: false,
          chirden: [
            {
              name: "人员管理平台",
              url: "http://web.verification.srthinker.com/",
              type: 3,
            },
            {
              name: "证码核验平台",
              url: "http://web.antiepidemic.srthinker.com/",
              type: 3,
            },
          ],
        },
      ],
      productList: [],
    };
  },

  created() {
    this.productList = this.menu[0].chirden;
    this.getToken();
  },

  methods: {
    getToken() {
      http
        .post("/admin/login", {
          username: "hhkf",
          password: "hhkf123",
        })
        .then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
          }
        });
    },
    // 打开菜单
    openMenu() {
      this.isShow = true;
    },
    closeMenu() {
      this.isShow = false;
    },
    // 选择菜单
    selectMenu(item) {
      if (item.chirden) {
        this.selectedMenuindex = item.index;

        this.productList = item.chirden;
      } else {
        this.isShow = false;

        this.$router.replace({
          name: item.url,
        });
      }
    },

    // 二级菜单展开
    showSecondary(index) {
      console.log(111);
      this.bottom[index].isShow = !this.bottom[index].isShow;
    },

    // 查看更多产品
    productMore(item) {
      if (!item.url) {
        return;
      }
      var name = this.$route.name;
      if (name == item.url) {
        return;
      }
      this.bottom.map((item) => {
        item.isShow = false;
      });

      switch (item.type) {
        case 1:
          this.$router.replace({
            name: "mobileProduct",
            query: {
              index: item.index,
            },
          });
          break;
        case 2:
          this.$router.replace({
            name: item.url,
          });
          break;
        case 3:
          window.open(item.url, "_blank");
          break;
      }
    },

    // 返回首页
    goback() {
      var name = this.$route.name;
      if (name == "mobileHome") {
        return;
      }

      this.$router.replace({
        name: "mobileHome",
      });
    },

    // 跳转页面
    jump(item) {
      if (!item.url) {
        return;
      }
      var name = this.$route.name;
      if (name == item.url) {
        return;
      }

      this.isShow = false;

      switch (item.type) {
        case 1:
          this.$router.replace({
            name: item.url,
          });
          break;

        case 2:
          window.open(item.url, "_blank");
          break;
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 3.5rem;
  padding: 0 1.3125rem;
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #000000;
}
.logo {
  width: 3.8125rem;
  height: 1.4375rem;
}
.menu {
  width: 1.25rem;
  height: 0.9375rem;
}
.bottom {
  width: 100%;
  padding-bottom: 1.9375rem;
  box-sizing: border-box;
  background: #272727;
}
.bottom_navigation {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom_navigation_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8125rem;
  font-size: 0.9375rem;
  padding: 0 1.3125rem;
  box-sizing: border-box;
  color: #919497;
}
.bottom_navigation_item:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8125rem;
  padding: 0 1.3125rem;
  box-sizing: border-box;
  font-size: 0.9375rem;
  color: #919497;
  border-bottom: 0.0625rem solid #919497;
}
.bottom_navigation_icon {
  width: 0.375rem;
  height: 0.75rem;
}
.bottom_company {
  font-size: 1.0625rem;
  color: #ffffff;
  text-align: left;
  margin: 1.9375rem 0 1.375rem;
}
.bottom_contact {
  font-size: 0.875rem;
  color: #919497;
  text-align: left;
  margin-bottom: 0.9375rem;
}
.bottom_business {
  margin-top: 3.875rem;
}
.bottom_copyright_icon {
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.3125rem;
}
.bottom_copyright {
  display: flex;
  align-items: center;
  color: #919497;
  font-size: 0.75rem;
}
.bottom_business_id {
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #919497;
  text-align: left;
}
.dowm_menu {
  position: fixed;
  top: 3.5rem;
  z-index: 999;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background: #07071f;
}
.menu_left {
  width: 9.125rem;
  height: 100%;
}
.menu_item {
  width: 100%;
  height: 3.25rem;
  padding-left: 1.9375rem;
  text-align: left;
  box-sizing: border-box;
  line-height: 3.25rem;
  color: white;
}
.menu_item_selected {
  background: #1a1a30;
}
.menu_product {
  width: 100%;
  height: 3.25rem;
  background: #1f1f31;
  text-align: left;
  line-height: 3.25rem;
  padding-left: 1.9375rem;
  box-sizing: border-box;
  color: white;
  margin-bottom: 0.0625rem;
}
.menu_right {
  flex: 1;
}
.close {
  width: 0.875rem;
  height: 0.875rem;
}
.bottom_navigation_secondary {
  width: 100%;
  height: 3.875rem;
  background: #3e3e3e;
  line-height: 3.875rem;
  text-align: left;
  color: #919497;
  font-size: 0.9375rem;
  padding-left: 1.3125rem;
  box-sizing: border-box;
}
.bottom_intro {
  padding: 0 1.3125rem;
  box-sizing: border-box;
}
.showdropdown {
  max-height: 60vh;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}
.nonedropdown2 {
  transition: max-height 0.5s ease-out;
  max-height: 0;
  overflow: hidden;
}
.bottom_navigation_icon2 {
  width: 0.75rem;
  height: 0.375rem;
}
</style>
