import Vue from "vue";
import VueRouter from "vue-router";
import Common from "../components/common.vue";
import Home from "../components/home.vue";
import About from "../components/about.vue";
import Product from "../components/product.vue";
import Rc13 from "../components/product/rc13.vue";
import Rc14 from "../components/product/rc14.vue";
import Pda from "../components/product/pda.vue";
import Stb2 from "../components/product/stb2.vue";
import Rc10 from "../components/product/rc10.vue";
import StudentCard from "../components/product/studentcard.vue";
import At21 from "../components/product/at21.vue";
import At31 from "../components/product/at31.vue";
import Park from "../components/solution/park.vue";
import Campus from "../components/solution/campus.vue";
import Public from "../components/solution/public.vue";
import Traffic from "../components/solution/traffic.vue";
import Cooperate from "../components/cooperate.vue";
import Construction from "../components/product/construction.vue";

// moblie
import Mobilecommont from "../moblie/common.vue";
import MobileHome from "../moblie/home.vue";
import mobileProduct from "../moblie/product/index.vue";
import MobileAbout from "../moblie/about/about.vue";
import MoblieRc13 from "../moblie/product/rc13.vue";
import MoblieAt21 from "../moblie/product/at21.vue";
import MoblieRc14 from "../moblie/product/rc14.vue";
import MoblieRc20 from "../moblie/product/rc20.vue";
import MoblieStb2 from "../moblie/product/stb2.vue";
import MoblieRc10 from "../moblie/product/rc10.vue";
import MoblieStudent from "../moblie/product/student.vue";
import MoblieAt31 from "../moblie/product/at31.vue";

import MobileCooperate from "../moblie/cooperate.vue";
import MobilePark from "../moblie/solution/park.vue";
import MobileCampus from "../moblie/solution/campus.vue";
import MobilePublic from "../moblie/solution/public.vue";
import MobileTraffic from "../moblie/solution/traffic.vue";
import MobileConstruction from "../moblie/product/construction.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/common",
    redirect: "/home",
    component: Common,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/product",
        name: "Product",
        component: Product,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/rc13",
        name: "Rc13",
        component: Rc13,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/at21",
        name: "At21",
        component: At21,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/rc14",
        name: "Rc14",
        component: Rc14,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/at31",
        name: "At31",
        component: At31,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/pda",
        name: "Pda",
        component: Pda,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/stb2",
        name: "Stb2",
        component: Stb2,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/rc14",
        name: "Rc14",
        component: Rc14,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/rc10",
        name: "Rc10",
        component: Rc10,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/studentCard",
        name: "StudentCard",
        component: StudentCard,
        meta: {
          title: "锶想科技",
          index: 1,
        },
      },
      {
        path: "/stb2",
        name: "Stb2",
        component: Stb2,
        meta: {
          title: "Stb2",
          index: 1,
        },
      },
      {
        path: "/about",
        name: "About",
        component: About,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/park",
        name: "Park",
        component: Park,
        meta: {
          title: "锶想科技",
          index: 2,
        },
      },
      {
        path: "/campus",
        name: "Campus",
        component: Campus,
        meta: {
          title: "锶想科技",
          index: 2,
        },
      },
      {
        path: "/public",
        name: "Public",
        component: Public,
        meta: {
          title: "锶想科技",
          index: 2,
        },
      },
      {
        path: "/traffic",
        name: "Traffic",
        component: Traffic,
        meta: {
          title: "锶想科技",
          index: 2,
        },
      },
      {
        path: "/cooperate",
        name: "Cooperate",
        component: Cooperate,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/construction",
        name: "Construction",
        component: Construction,
        meta: {
          title: "锶想科技",
        },
      },
         {
        path: "/mobile/cooperate",
        name: "cooperate",
        component: MobileCooperate,
        meta: {
          title: "锶想科技",
        },
      },
    ],
  },

  // 手机页面
  {
    path: "/mobile/",
    redirect: "/mobile/home",
  },
  {
    path: "/mobile/common",
    redirect: "/home",
    component: Mobilecommont,
    children: [
      {
        path: "/mobile/home",
        name: "mobileHome",
        component: MobileHome,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product",
        name: "mobileProduct",
        component: mobileProduct,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/rc13",
        name: "moblierc13",
        component: MoblieRc13,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/at21",
        name: "moblieat21",
        component: MoblieAt21,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/rc14",
        name: "MoblieRc14",
        component: MoblieRc14,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/rc20",
        name: "MoblieRc20",
        component: MoblieRc20,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/stb2",
        name: "MoblieStb2",
        component: MoblieStb2,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/rc10",
        name: "MoblieRc10",
        component: MoblieRc10,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/student",
        name: "MoblieStudent",
        component: MoblieStudent,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/product/at31",
        name: "MoblieAt31",
        component: MoblieAt31,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/about",
        name: "about",
        component: MobileAbout,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/cooperate",
        name: "Mcooperate",
        component: MobileCooperate,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/park",
        name: "park",
        component: MobilePark,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/campus",
        name: "campus",
        component: MobileCampus,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/public",
        name: "public",
        component: MobilePublic,
        meta: {
          title: "锶想科技",
        },
      },
      {
        path: "/mobile/traffic",
        name: "traffic",
        component: MobileTraffic,
        meta: {
          title: "锶想科技",
        },
      },
           {
        path: "/mobile/construstion",
        name: "construstion",
        component: MobileConstruction,
        meta: {
          title: "锶想科技",
        },
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
