<template>
  <div class="synopsis">
    <img src="@/assets/cooperate/banner.png" class="banner" />
    <div class="center">
      <div class="center_title">锶想能为你提供</div>
      <div class="center_content">
        <div class="center_item">
          <img src="@/assets/cooperate/icon1.png" class="center_item_img" />
          <div class="center_item_text">优质服务</div>
          <div class="center_item_sub">
            具有一站式的项目实施落地和持续服务的能力,1：1服务终端客户
          </div>
        </div>
        <div class="center_item">
          <img src="@/assets/cooperate/icon2.png" class="center_item_img" />
          <div class="center_item_text">产品培训</div>
          <div class="center_item_sub">
            能为您提供商务支持和线上、线下产品服务培训
          </div>
        </div>
        <div class="center_item">
          <img src="@/assets/cooperate/icon3.png" class="center_item_img" />
          <div class="center_item_text">技术支持</div>
          <div class="center_item_sub">
            售前、售后技术支持快速响应,让您后顾无忧
          </div>
        </div>
      </div>
    </div>
    <div class="contactus">
      <div class="contactus_title">联系我们</div>
      <div class="contactus_sub">留下联系方式，以便为您提供更好的服务</div>
      <div class="contactus_form">
        <el-form
          label-position="top"
          label-width="21.25rem"
          :model="form"
          ref="formLabel"
          :rules="rules"
        >
          <el-form-item label="公司名称:" prop="company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="您的姓名:" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="您的诉求:" prop="ask">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="form.ask"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="contactus_bto" @click="çommit()">提交</div>
      </div>
    </div>
    <div class="partner">
      <div class="industry_title">合作伙伴</div>
      <vue-seamless-scroll
        :data="partnersList"
        :class-option="optionLeft"
        class="horserace"
      >
        <ul class="horserace_item_list">
          <li v-for="item in partnersList" :key="item" class="horserace_item">
            <img :src="item" class="horserace_item" />
          </li>
        </ul>
        <ul class="horserace_item_list">
          <li v-for="item in partnersList2" :key="item" class="horserace_item">
            <img :src="item" class="horserace_item" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import parner1 from "../assets/partners/1.png";
import parner2 from "../assets/partners/2.png";
import parner3 from "../assets/partners/3.png";
import parner4 from "../assets/partners/4.png";
import parner5 from "../assets/partners/5.png";
import parner6 from "../assets/partners/6.png";
import parner7 from "../assets/partners/7.png";
import parner8 from "../assets/partners/8.png";
import parner9 from "../assets/partners/9.png";
import parner10 from "../assets/partners/10.png";
import parner11 from "../assets/partners/11.png";
import parner12 from "../assets/partners/12.png";
import parner13 from "../assets/partners/13.png";
import parner14 from "../assets/partners/14.png";
import parner15 from "../assets/partners/15.png";
import parner16 from "../assets/partners/16.png";
import parner17 from "../assets/partners/17.png";
import parner18 from "../assets/partners/18.png";
import parner19 from "../assets/partners/19.png";
import parner20 from "../assets/partners/20.png";

import vueSeamlessScroll from "vue-seamless-scroll";
import http from "../utils/http";
export default {
  data() {
    const valiatephone = (rule, value, callback) => {
      var res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;

      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!res.test(value)) {
        callback(new Error("手机号格式错误，请重新填写"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      partnersList: [
        parner1,
        parner2,
        parner3,
        parner4,
        parner5,
        parner11,
        parner12,
        parner13,
        parner14,
        parner15,
      ],
      partnersList2: [
        parner6,
        parner7,
        parner8,
        parner9,
        parner10,
        parner16,
        parner17,
        parner18,
        parner19,
        parner20,
      ],

      rules: {
        company: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, validator: valiatephone, trigger: "blur" }],
      },
    };
  },

  components: { vueSeamlessScroll },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      };
    },
  },
  methods: {
    çommit() {
      this.$refs["formLabel"].validate((valid) => {
        if (valid) {
          var map = {};
          map = this.form;
          if (!this.form.ask) {
            map.ask = "无";
          }
          http.post("/mail/home_send", this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.form = {};
              this.$refs["formLabel"].clearValidate();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.top {
  width: 101.75rem;
  height: 27.9375rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.company_img {
  width: 38.4375rem;
  height: 21.6875rem;
  position: absolute;
  right: 0;
  top: 0;
}
.title {
  text-align: left;
  font-size: 3.25rem;
  color: #333333ff;
  font-weight: bold;
  margin-bottom: 2.75rem;
}
.introduce {
  width: 52.375rem;
  font-size: 1.125rem;
  text-align: left;
  color: #333333ff;
  margin-bottom: 1rem;
}
.center {
  width: 100%;
  padding: 6.25rem 0;
  background: #fbfbfbff;
  margin: 0 auto;
}
.center_title {
  font-size: 3.125rem;
  color: #030303ff;
  font-weight: bold;
}
.center_content {
  display: flex;
  width: 101.75rem;
  margin: 6.25rem auto 0;
  align-items: center;
  justify-content: space-between;
}
.center_item {
  width: 31.25rem;
  height: 28.125rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center_item_img {
  width: 8.8125rem;
  height: 8.8125rem;
  margin-bottom: 2.6875rem;
}
.center_item_text {
  font-size: 1.625rem;
  font-weight: bold;
  color: #333333ff;
}

.v-enter-active {
  animation: myAni 1s;
}

.v-leave-active {
  animation: myAni 0.2s reverse;
}

@keyframes myAni {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0rem);
  }
}
.bottom {
  width: 100%;
  height: 111.75rem;
  padding-top: 6.25rem;
  box-sizing: border-box;
  position: relative;
}
.glory {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bottom_title {
  font-size: 3.125rem;
  color: white;
  text-align: center;
}
.bottom_imglist {
  width: 101.75rem;
  margin: 6.25rem auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_item {
  width: 32.625rem;
  height: 20.75rem;
  margin-bottom: 1.875rem;
}
.banner {
  width: 100%;
  height: 46.25rem;
}
.center_item_sub {
  width: 18.5rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: center;
  margin-top: 2.3125rem;
}
.partner {
  padding-top: 6.25rem;
  width: 100%;
  height: 40rem;
  background: white;
}
.industry_title {
  font-size: 3.125rem;
  color: #030303ff;
  font-weight: bold;
}
.industry_tips {
  font-size: 1.75rem;
  color: #333333ff;
  margin-top: 0.875rem;
}
.industry_swiper {
  margin: 6.25rem 0 10rem;
}
.industry_item {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 31.875rem;
  height: 47.625rem;
  padding-top: 4.25rem;
  box-sizing: border-box;
}
.industry_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.is-active .industry_item {
  width: 36.25rem !important;
}
.industry_swiper_title {
  font-size: 2.9375rem;
  color: white;
  font-weight: 500;
}
.industry_swiper_tips {
  font-size: 1.5625rem;
  color: white;
  margin: 1rem 0 1.25rem;
  font-weight: 500;
}
.industry_swiper_more {
  display: flex;
  align-items: center;
  font-size: 1.4375rem;
  justify-content: center;
  color: rgba(250, 250, 250, 0.6);
}
.industry_swiper_moreicon {
  width: 0.375rem;
  height: 0.6875rem;
  margin-left: 0.625rem;
}
.horserace {
  width: 101.875rem;
  margin: 3.125rem auto 10rem;
  height: 17.5rem;
  overflow: hidden;
}
.horserace_item_list {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}
.horserace_item {
  width: 18.875rem;
  height: 8.75rem;
}
.contactus {
  width: 100%;
  height: 63.0625rem;
  padding-top: 6.25rem;
  box-sizing: border-box;
  background: #f7f7feff;
}
.contactus_title {
  font-size: 3.125rem;
  font-weight: bold;
  color: #030303ff;
}
.contactus_sub {
  font-size: 1.25rem;
  color: #6c6c6fff;
  margin-top: 0.875rem;
}
.contactus_form {
  width: 38.5rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0rem 0rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.16);
  padding: 2.5rem 4.875rem;
  margin: 5rem auto 0;
}
.contactus_item {
  margin-top: 0.625rem;
}
.contactus_item_title {
  text-align: left;
  font-size: 1rem;
  color: #000000ff;
  margin-bottom: 1rem;
}
::v-deep .el-input {
  height: 3rem;
}
.contactus_bto {
  width: 28.375rem;
  height: 3.125rem;
  background: #448ef7;
  border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
  margin: 4.9375rem auto 0;
  color: white;
  cursor: pointer;
  line-height: 3.125rem;
  margin-top: 2.8125rem;
  text-align: center;
}
</style>