var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[_vm.distance > 0 ? 'adsorption' : 'header'],on:{"mouseover":_vm.cancelDiglog}},[_c('div',{staticClass:"header_tab_left"},[_c('img',{staticClass:"header_logo",attrs:{"src":require("../assets/logo2.png")},on:{"click":function($event){return _vm.switchPage(_vm.firstPage)}}}),_c('div',{staticClass:"header_tab"},_vm._l((_vm.navi),function(item){return _c('div',{key:item.index,staticClass:"tab",on:{"click":function($event){return _vm.switchPage(item)}}},[_c('div',{class:[
              _vm.selectIndex == item.index ? 'header_tab_select' : '',
              _vm.distance > 0 && _vm.selectIndex == item.index
                ? 'header_tab_select2'
                : '',
            ],on:{"mouseover":function($event){$event.stopPropagation();return _vm.mousein(item.index)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.index == 2)?_c('div',{class:[
              'solution',
              _vm.isProgramme ? 'showdropdown2' : 'nonedropdown2',
            ],on:{"mouseleave":_vm.cancelDiglog,"mouseover":function($event){$event.stopPropagation();return _vm.mousein(2)}}},_vm._l((_vm.solutionList),function(item){return _c('div',{key:item.name,on:{"click":function($event){return _vm.skip(item.url)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(item.index == 5)?_c('div',{class:['open', _vm.isOpen ? 'showdropdown3' : 'nonedropdown3'],on:{"mouseleave":_vm.cancelDiglog,"mouseover":function($event){$event.stopPropagation();return _vm.mousein(5)}}},_vm._l((_vm.openPlatform),function(item){return _c('div',{key:item.name,on:{"click":function($event){return _vm.openWindow(item.url)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()])}),0)])]),_c('div',{class:['dropdown', _vm.isProduct ? 'showdropdown' : 'nonedropdown'],on:{"mouseleave":_vm.cancelDiglog}},[_vm._l((_vm.productList),function(item){return _c('div',{key:item.name,staticClass:"dropdown_item",on:{"click":function($event){return _vm.skip(item.url)}}},[_c('img',{staticClass:"dropdown_img",attrs:{"src":item.img}}),_c('div',{staticClass:"dropdown_title"},[_vm._v(_vm._s(item.name))])])}),_c('div',{staticClass:"dropdown_more",on:{"click":function($event){return _vm.skip('/product')}}},[_c('div',[_vm._v("查看更多")]),_c('img',{staticClass:"dropdown_righticon",attrs:{"src":require("@/assets/product/right.png")}})])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }