<template>
  <div class="synopsis">
    <div class="top">
      <transition>
        <div v-if="isshow">
          <div class="title">关于锶想</div>
          <div class="introduce">
            锶想（广州）科技有限公司是华汇集团（2009年在北京中关村成立）旗下一家专注人工智能类软硬件产品研发、生产及销售的高科技公司。公司主营产品有实名制SAAS服务器，实名制核验终端、车联网实名制终端、电子哨兵终端、SIM卡自助售卖终端、电子身份证核验终端等产品。
          </div>
          <div class="introduce">
            主要服务于三大运营商（中国移动、中国联通、中国电信）、广电、公安一所、公安三所、中国邮政、上
            汽、国家电网、民生银行、北京铁路局、国家博物馆、上海CA等客户。锶想始终不渝地为客户提供一流的
            产品、技术与服务，不断提升客户价值。
          </div>
        </div>
      </transition>
      <img src="@/assets/about/company.png" class="company_img" />
    </div>
    <div class="center">
      <div class="center_title">三大核心能力</div>
      <div class="center_content">
        <div class="center_item">
          <img src="@/assets/about/icon1.png" class="center_item_img" />
          <div class="center_item_text">智能终端</div>
        </div>
        <div class="center_item">
          <img src="@/assets/about/icon2.png" class="center_item_img" />
          <div class="center_item_text">物联网数据中心</div>
        </div>
        <div class="center_item">
          <img src="@/assets/about/icon3.png" class="center_item_img" />
          <div class="center_item_text">自研算法</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="@/assets/about/glory.png" class="glory" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow: false,
    };
  },
  created() {
    setTimeout(() => {
      this.isshow = true;
    }, 500);
  },
};
</script>

<style scoped>
.top {
  width: 101.75rem;
  height: 27.9375rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.company_img {
  width: 38.4375rem;
  height: 21.6875rem;
  position: absolute;
  right: 0;
  top: 0;
}
.title {
  text-align: left;
  font-size: 3.25rem;
  color: #333333ff;
  font-weight: bold;
  margin-bottom: 2.75rem;
}
.introduce {
  width: 52.375rem;
  font-size: 1.125rem;
  text-align: left;
  color: #333333ff;
  margin-bottom: 1rem;
}
.center {
  width: 100%;
  padding: 6.25rem 0;
  background: #fbfbfbff;
  margin: 0 auto;
}
.center_title {
  font-size: 3.125rem;
  color: #030303ff;
  font-weight: bold;
}
.center_content {
  display: flex;
  width: 101.75rem;
  margin: 6.25rem auto 0;
  align-items: center;
  justify-content: space-between;
}
.center_item {
  width: 31.25rem;
  height: 25rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center_item_img {
  width: 8.8125rem;
  height: 8.8125rem;
  margin-bottom: 2.6875rem;
}
.center_item_text {
  font-size: 1.5625rem;
  color: #333333ff;
}

.v-enter-active {
  animation: myAni 1s;
}

.v-leave-active {
  animation: myAni 0.2s reverse;
}

@keyframes myAni {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0rem);
  }
}
.bottom {
  width: 100%;
  height: 67.5rem;
  padding-top: 6.25rem;
  box-sizing: border-box;
  position: relative;
}
.glory {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bottom_title {
  font-size: 3.125rem;
  color: white;
  text-align: center;
}
.bottom_imglist {
  width: 101.75rem;
  margin: 6.25rem auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_item {
  width: 32.625rem;
  height: 20.75rem;
  margin-bottom: 1.875rem;
}
</style>